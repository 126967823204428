import React, { useMemo } from 'react'

import { isWeekend } from 'date-fns'
import { capitalize } from 'lodash-es'
import { IoIosWarning } from 'react-icons/io'

import { Box } from '../../components/Box/Box'
import { HeaderDate, HeaderIcon, HeaderText, HeaderTitle, HeaderYear, PageHeader } from '../../components/PageHeader/PageHeader'
import { Paper } from '../../components/Paper/Paper'
import { ReactComponent as DesignacoesMecanicasIcon } from '../../icons/designacoes-mecanicas.svg'
import { dateFormat, formatYearMonth, getYearMonthFromDateRange } from '../../utils/date'
import { Actors, Date as DateComponent, DateRow, DatesContainer, DesignacoesTable, Info, InfoTitle, Role, RolesRow, SpecialOcasion } from './styles'
import { Data } from './types'

interface MonthProps {
  weeks: Data['weeks']
}

export function Month({ weeks }: MonthProps) {
  const [year, month] = useMemo((): [string, string] => {
    if (!weeks) return formatYearMonth('')

    const [start, ...dates] = weeks.map(week => week.overrideMonth
      ? new Date(`${week.overrideMonth}-02`) // use day 02 to prevent timezone interference
      : Array.isArray(week.date) ? week.date[0] : week.date)
    const end = dates.pop() ?? start
    return formatYearMonth(getYearMonthFromDateRange(start, end))
  }, [weeks])

  return (
    <Box fill>
      <PageHeader bg="/header/designacoes-mecanicas.jpg">
        <HeaderIcon>
          <DesignacoesMecanicasIcon />
        </HeaderIcon>
        <HeaderText>
          <HeaderTitle small>Designações Mecânicas</HeaderTitle>
          <HeaderDate>
            {month}<br />
            <HeaderYear>{year}</HeaderYear>
          </HeaderDate>
        </HeaderText>
      </PageHeader>

      <Paper fill>
        <DesignacoesTable>
          <RolesRow>
            <Role />
            <Role>Indicador</Role>
            <Role>Volante</Role>
            <Role>Áudio</Role>
            <Role>Vídeo</Role>
          </RolesRow>
          <DatesContainer>
            {weeks.map(week => {
              const dates = Array.isArray(week.date) ? week.date : [week.date]
              return (
                <DateRow key={dates.map(d => d.toISOString()).join(';')}>
                  <DateComponent weekend={dates.some(isWeekend)}>
                    {React.Children.toArray(dates.map(date => dates.length > 1
                      ? (
                        <small>
                          {capitalize(dateFormat(date, 'EEEEEE'))}{' '}
                          {dateFormat(date, 'dd/MM')}<br />
                        </small>
                      )
                      : (
                        <>
                          {capitalize(dateFormat(date, 'EEEE'))}<br />
                          {dateFormat(date, 'dd/MM')}
                        </>
                      )))}
                  </DateComponent>
                  {week.specialOcasion ? (
                    <SpecialOcasion colSpan={5}>{week.specialOcasion}</SpecialOcasion>
                  ) : (
                    <>
                      {React.Children.toArray([week.attendants, week.microphone, week.audio, week.video].map(actors => (
                        <Actors>
                          {actors?.length
                            ? React.Children.toArray(actors.map(item => <div>{item}</div>))
                            : <strong>-</strong>}
                        </Actors>
                      )))}
                    </>
                  )}
                </DateRow>
              )
            })}
          </DatesContainer>
        </DesignacoesTable>

        <Info>
          <InfoTitle><IoIosWarning /> Atenção:</InfoTitle>
          {' '}
          É necessário que os designados para: <strong>Presidente</strong>, <strong>Indicadores <em>(Zoom e Salão do Reino)</em></strong> e <strong>Operadores de Áudio e Vídeo</strong> cheguem com <strong>20 minutos</strong> de antecedência.
        </Info>
      </Paper>
    </Box>
  )
}
